// import files
@import '/node_modules/leaflet/dist/leaflet.css'


$font-path: '/public/assets/fonts'

@font-face
	src: url($font-path + '/ApfelGrotezk-Regular.woff2') format('woff2')
	font-family: 'Apfel Grotezk'
@font-face
	src: url($font-path + '/ApfelGrotezk-Fett.woff2') format('woff2')
	font-family: 'Apfel Grotezk'
	font-weight: bold
@font-face
	src: url($font-path + '/ApfelGrotezk-Brukt.woff2') format('woff2')
	font-family: 'Apfel Grotezk Brut'


// variables
$font-stack: 'Arial', sans-serif
$font-stack-apfel: 'Apfel Grotezk Brut', 'Arial', sans-serif
$font-size-3xl: 50px
$font-size-2xl: 32px
$font-size-xl: 28px
$font-size-l: 22px
$font-size-s: 16px
$font-size-xs: 14px

$color-txt: #000
$color-meta: #999
$color-bg: #fff
$color-red: #ff0000

$map-width: 100%
$sidebar-width-mobile: 100%
$sidebar-width: 40%
$nav-height: 50px
$padding-desktop: 30px
$padding-mobile: 15px

$icon-radius-s: 15px
$icon-radius-l: 25px

$layout-breakpoint-tablet: 800px
$layout-breakpoint-desktop: 1280px




// MOBILE
.desktop, .tablet
	display: none

.welcome-banner
	position: fixed
	transition: transform .25s
	// width: 100%
	// height: 100%
	z-index: 1000
	color: $color-bg
	font-family: $font-stack-apfel
	text-transform: uppercase
	font-size: $font-size-3xl
	line-height: $nav-height
	div
		height: $nav-height
		padding: 0 $padding-mobile
		background-color: $color-red
		display: inline-block
	// background-image: url('../../assets/images/logo_mobile.svg')
	// background-position: center
	// background-size: contain
	// background-repeat: no-repeat

body
	font: $font-size-l $font-stack
	background-color: $color-bg
	color: $color-txt
	margin: 0

h1, h2, h3
	font-weight: normal
	font-size: $font-size-l

a
	text-decoration-color: $color-red
	color: $color-red
	text-decoration: none

ul, li
	list-style: none
	margin: 0
	padding: 0

// navigation
nav
	padding: 0 $padding-mobile
	height: $nav-height
	box-sizing: border-box
	background-color: $color-red
	position: fixed
	bottom: 0
	left: 0
	width: 100%
	z-index: 100
	text-align: center
	display: flex
	justify-content: space-between
	align-items: center
	.nav-link
		padding: 0 0
		margin: 0 0
		a
			text-transform: uppercase
			font-family: $font-stack-apfel
			color: $color-bg
			text-decoration: none
			font-size: $font-size-xl
			// font-weight: bold
	.active
		text-decoration: underline
		text-decoration-color: $color-bg

// sidebar
.content
	box-sizing: border-box
	background-color: $color-bg
	width: $sidebar-width-mobile
	min-height: 100vh
	position: absolute
	padding-bottom: calc($padding-mobile + $nav-height)
	z-index: 100
	p
		max-width: 650px
	.header
		position: sticky
		top: 0
		z-index: 100
		background-color: $color-red
		color: $color-bg
		height: $nav-height
		display: flex
		align-items: center
		font-weight: bold
		padding: 0 $padding-mobile


// spaces
.spaces.content
	padding-bottom: $nav-height
	.header
		font-family: $font-stack-apfel
		font-size: $font-size-2xl
		font-weight: normal
		text-transform: uppercase
	a
		text-decoration: none
		color: $color-txt
	a:hover
		.name
			.circle-icon
				background-color: $color-txt
			h2
				color: $color-txt
	.space
		border-bottom: 2px solid $color-red
		// padding-bottom: 1em
		.name
			padding: 0 $padding-mobile 0 $padding-mobile
			display: inline-flex
			align-items: center
			h2
				color: $color-red
				font-weight: bold
			.circle-icon
				min-width: $icon-radius-l
				margin-right: 1rem
		.event, .exhibition
			padding: 0 $padding-mobile 1em $padding-mobile
		.meta
			font-size: $font-size-s
			color: $color-meta
		.space-details
			padding: 1em $padding-mobile 0 $padding-mobile
			color: $color-txt

// space
.space.content
	padding-bottom: $nav-height
	.spaces-nav
		width: 20%
		display: flex
		justify-content: space-between
	.header
		display: flex
		justify-content: space-between
		align-items: center
		a
			color: $color-bg
	.space
		padding-left: $padding-mobile
		padding-right: $padding-mobile
		padding-bottom: 1em
		.space-description
			margin-top: 2em
			font-size: $font-size-s
			color: $color-meta
		a
			text-decoration: none
			text-decoration-color: $color-red
		.name
			display: inline-flex
			align-items: center
			.circle-icon
				min-width: $icon-radius-l
				margin-right: 1rem
			h2	
				color: $color-red
				font-weight: bold
		.address, .website
			font-size: $font-size-s
			a
				text-decoration: none
				color: $color-txt
		.directions
			display: flex
			align-items: center
			justify-content: space-between
			margin-top: .5em
			font-size: $font-size-s
			font-weight: bold
			color: $color-red
			a
				text-decoration: underline
				text-decoration-color: $color-red
		.event, .exhibition
				margin-top: 1em
				.meta
					font-size: $font-size-s
					color: $color-meta
				.date
					font-size: $font-size-s
					padding-bottom: 0.25em
					color: $color-meta
				.calendar
					color: $color-red
					padding-bottom: .25em
					padding-top: .5em
				.tag
					color: $color-meta
					font-size: $font-size-s
					padding-bottom: 0
				.title
					padding-top: 0em
					font-size: 1em
					max-width: 650px
				.artists
					font-size: $font-size-s
					max-width: 650px
				.description
					font-size: $font-size-s
					max-width: 650px
				.link
					padding-top: .5em
					font-size: $font-size-s
					font-weight: bold
					text-decoration: underline
					text-decoration-color: $color-red
					a
						text-decoration-color: $color-red
		.space-link
			padding-top: 1em
			font-size: 0.75em
			a
				text-decoration-color: $color-red

// /events
.events.content
	padding-bottom: 2em
	.calendar-date
		padding-left: $padding-mobile
		height: $nav-height
		display: flex
		align-items: center
		box-sizing: border-box
		position: sticky
		top: 0
		z-index: 100
		background-color: $color-red
		font-size: $font-size-2xl
		font-family: $font-stack-apfel
		text-transform: uppercase
		div
			// font-weight: bold
			color: $color-bg
			margin: 0
	.event
		border-bottom: 2px solid $color-red
		padding: 0.75em $padding-mobile 0.75em $padding-mobile
		.calendar-buttons
			margin-bottom: .75em
			color: $color-red
			text-decoration: underline
		.link
			a
				font-weight: bold
				font-size: $font-size-s
		.description
			font-size: $font-size-s
			max-width: 650px
			.excerpt-button
				color: $color-red
				display: inline
				line-height: 1
			.full
				display: none
			.excerpt
				padding-top: 1em
				max-width: 650px
		.description.show
			.excerpt
				display: none
				max-width: 650px
			.full
				display: inline
	.space
		padding-bottom: 0em
		margin-top: 1em
		font-size: $font-size-s
		.name
			display: inline-flex
			align-items: center
			font-weight: bold
			a
				text-decoration: none
			.circle-icon
				margin-right: 0.5rem
				height: $icon-radius-s
				width: $icon-radius-s
				min-width: $icon-radius-s
		.name:hover
			color: $color-txt
			.circle-icon
				background-color: $color-txt

// info
.info
	border: 2px solid $color-red
	background-color: $color-red
	color: $color-bg
	font-size: $font-size-l
	.wrapper
		padding-left: $padding-mobile
		padding-right: $padding-mobile
	.description
		padding-bottom: 1em
	.banner
		font-size: $font-size-3xl
		font-family: $font-stack-apfel
		text-transform: uppercase
		padding-top: 2em
		padding-bottom: 3em
	a
		text-decoration-color: $color-bg
		text-decoration: underline
		color: $color-bg
	h1
		font-size: $font-size-xl
		font-weight: bold
	h2
		font-size: $font-size-l
	h3
		font-size: $font-size-s
		font-weight: normal
	.more-info
		font-size: $font-size-s
	.supporters
		padding-top: 4em
		.logos
			display: flex
			width: 100%
			align-items: center
			justify-content: left
			flex-wrap: wrap
			gap: 2em
			.logo
				height: 2em
				img
					height: 100%
	.footer
		padding-top: 4em
		font-size: $font-size-xs

// buttons
add-to-calendar-button
	padding: 0
	margin: 0
button, a.button
	display: block
	background: none
	color: inherit
	font-weight: bold
	// opacity: 0.75
	border: none
	// border-radius: 50px
	padding: 0
	padding-right: .5rem
	margin: 0
	font-size: $font-size-s
	cursor: pointer
	outline: inherit
	text-decoration: underline
	// text-decoration-thickness: 2px

// signup form
input
	background: $color-red
	color: $color-bg
	font: inherit
	border: none
	// border-radius: 50px
	padding: 0 0.5em
	font-size: 0.75em
	&[type="submit"]
		width: auto
		cursor: pointer

.input-sizer
	display: inline-grid
	vertical-align: top
	align-items: center
	position: relative
	color: $color-bg!important
	input
		padding: 0.2em 0.5em
		min-width: 200px
		border: none
		border-bottom: 1px solid $color-bg
		border-radius: 0

.input-sizer::after,
input,
textarea
	color: $color-bg
	width: auto
	min-width: 1em
	grid-area: 1 / 2
	padding: 1em 0
	font-size: $font-size-s
	resize: none
	background: none
	appearance: none

.input-sizer::after
	content: attr(data-value) ' '
	visibility: hidden
	white-space: pre-wrap

#mce-success-response,
#mce-error-response,
#mc_embed_signup div.mce_inline_error
	font-size: $font-size-s
	font-weight: normal!important
	display: inline
	background-color: inherit

#mc-embedded-subscribe.button
	background-color: $color-red
	color: $color-bg
	margin-top: 0.5em
	padding: 0.25em 0
	font-size: $font-size-s
	font-weight: normal
	text-decoration: underline

#mc_embed_signup div.mce_inline_error
	font-size: $font-size-s
	font-weight: normal!important
	margin-bottom: 0
	color: $color-bg!important
	background: inherit!important

// map
#map
	border-bottom: none
	box-sizing: border-box
	width: $map-width
	height: calc(100% - $nav-height)
	z-index: -10
	position: fixed
	left: 0
	top: 0
	background: $color-bg

.active-area
	position: absolute
	left: 0
	height: 100%
	width: 100%

.leaflet-container
	font-family: $font-stack

.circle-icon
	background-color: $color-red
	border-radius: 100px
	width: $icon-radius-l
	height: $icon-radius-l
	span
		line-height: 1em
		display: none
		position: absolute
		left: 30px
		top: 50%
		transform: translateY(-50%)
		width: 100px
		font-size: $font-size-l*0.75
	.mobile
		display: none
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		font-size: $font-size-l
		font-family: monospace
	.highlight
		display: none
		background-color: $color-txt
		border-radius: 100px
		width: 25px
		height: 25px

.active	
	.mobile, span
		display: block

.leaflet-fade-anim
	transition: none

.leaflet-control-attribution
	font-size: 0.6em
	color: $color-txt
	a
		color: $color-txt

div
	.leaflet-popup-content-wrapper
		border-radius: 0
		background-color: $color-bg
		box-shadow: none
		font: 16px $font-stack
		text-align: center
		border: 1px solid $color-txt
		// box-sizing: border-box
		a
			color: $color-txt
			text-decoration: none
	.leaflet-popup-close-button
		display: none
	.leaflet-popup-tip
		display: none
		background-color: $color-bg
		box-shadow: none
		font: 16px $font-stack

// scrollbar
// ::-webkit-scrollbar
// 	width: $scrollbar-width

// ::-webkit-scrollbar-track
// 	background: $color-bg
// 	border-left: 2px solid $color-txt

// ::-webkit-scrollbar-thumb
// 	background: $color-txt

// ::-webkit-scrollbar-thumb:hover
// 	background: $color-txt

// swup animations
.transition-slide
	transition: 0.175s
	transform: translateX(0)

html.is-animating .transition-slide
	transform: translateX(-100vw)

html.is-animating.from-route-info .transition-slide
	transform: translateX(-100vw)

html.is-animating.to-route-info .transition-slide
	transform: translateX(-100vw)

html.is-animating
	cursor: progress!important
	*
		cursor: progress!important





// TABLET
@media (min-width: $layout-breakpoint-tablet)

	.welcome-banner
		// position: fixed
		// width: 100%
		// height: 100%
		// z-index: 1000
		// background-image: url('../../assets/images/logo_tablet.svg')
		// background-position: center
		// background-size: contain
		// background-repeat: no-repeat

	.mobile
		display: none

	.tablet
		display: inline

	.flex
		width: 100%
		display: flex
		gap: $padding-desktop*2	
		flex-wrap: wrap
		.two
			width: 100%
			p
				max-width: 800px
		.four
			width: 45%

	// sidebar
	.content
		width: 50%
		border-right: 2px solid $color-red
		.header
			padding-left: $padding-desktop
			padding-right: $padding-desktop

	.info.content
		padding-bottom: $padding-desktop
		.wrapper
			padding-left: $padding-desktop
			padding-right: $padding-desktop
		.header-logo
			padding: 0 20px

	.space.content
		padding-bottom: 0
		.space
			padding-left: $padding-desktop
			padding-right: $padding-desktop

	.spaces.content
		padding-bottom: 0
		.space
			.name, .event, .exhibition, .space-details
				padding-left: $padding-desktop
				padding-right: $padding-desktop

	.events.content
		padding-bottom: 0
		.calendar-date
			top: 0
			text-align: left
			// padding-left: calc(25% + $padding-desktop/2)
			padding-left: $padding-desktop
		.event
			.description
				.cover
					width: calc(75% - $padding-desktop)
			.calendar-buttons
				margin-bottom: 0
				margin-top: .75em
			padding-left: $padding-desktop
			padding-right: $padding-desktop
			display: flex
			flex-wrap: nowrap
		.date
				width: 25%
		.event-flex
				width: 75%

	add-to-calendar-button
		display: flex
		align-items: center
		text-align: left

	nav
		width: 50%
		left: unset
		right: 0
		top: 0
		padding: 0 $padding-mobile
		.nav-link
			margin: 0 0
			a
				font-size: $font-size-2xl

	.content.info
		padding-top: $nav-height
		width: 100%
		border: none
		.banner
			font-size: $font-size-3xl

	#map
		height: 100%
		top: 0
	.active-area
		left: 50%
		width: 50%
	.leaflet-control-attribution
		font-size: 0.8em

	.circle-icon
		span
			width: 200px
		.highlight
			display: none
	
	.active	
		.mobile
			display: none
	
	.clicked
		.highlight
			display: inline-block

	html.is-animating .transition-slide
		transform: translateX(-50%)
	
	html.is-animating.to-route-info .transition-slide
		transform: translateX(-50%)





// DESKTOP
@media (min-width: $layout-breakpoint-desktop)

	.welcome-banner
		// position: fixed
		// width: 100%
		// height: 100%
		// z-index: 1000
		// background-image: url('../../assets/images/logo_desktop.svg')
		// background-position: center
		// background-size: contain
		// background-repeat: no-repeat

	.mobile
		display: none
	
	.desktop
		display: inline

	.content
		width: $sidebar-width
		max-width: 800px

	#map
		.active-area
			left: $sidebar-width
			width: calc($map-width - $sidebar-width)

	nav
		width: 50%
		max-width: 800px
		.nav-link
			// margin: 0 $padding-mobile
			a
				font-size: $font-size-3xl

	.info.content
		max-width: 100%
		.header-logo
			width: 85%
			padding: 0
		.tablet
			display: none

	.spaces.content
		.header
			font-size: $font-size-3xl

	.events.content
		.calendar-date
			font-size: $font-size-3xl

	.flex
		width: 100%
		display: flex
		gap: $padding-desktop*2
		flex-wrap: nowrap	
		.two
			width: 100%
		.four
			width: 25%




@media (min-width: 2000px)
	
	#map
		.active-area
			left: 800px
			width: calc(100% - 800px)