.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  box-sizing: border-box;
  z-index: 800;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  width: 26px;
  height: 26px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  width: 36px;
  height: 36px;
  background-image: url("layers.760a0456.png");
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  width: 1em;
  height: .6669em;
  vertical-align: baseline !important;
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  text-shadow: 1px 1px #fff;
  background: #fffc;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  min-height: 1px;
  margin: 13px 24px 13px 20px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-content p {
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  pointer-events: none;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  pointer-events: auto;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  width: 24px;
  height: 24px;
  color: #757575;
  background: none;
  border: none;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@font-face {
  src: url("ApfelGrotezk-Regular.006cbe56.woff2") format("woff2");
  font-family: Apfel Grotezk;
}

@font-face {
  src: url("ApfelGrotezk-Fett.d73820b4.woff2") format("woff2");
  font-family: Apfel Grotezk;
  font-weight: bold;
}

@font-face {
  src: url("ApfelGrotezk-Brukt.32d0f8e1.woff2") format("woff2");
  font-family: Apfel Grotezk Brut;
}

.desktop, .tablet {
  display: none;
}

.welcome-banner {
  z-index: 1000;
  color: #fff;
  text-transform: uppercase;
  font-family: Apfel Grotezk Brut, Arial, sans-serif;
  font-size: 50px;
  line-height: 50px;
  transition: transform .25s;
  position: fixed;
}

.welcome-banner div {
  height: 50px;
  background-color: red;
  padding: 0 15px;
  display: inline-block;
}

body {
  color: #000;
  background-color: #fff;
  margin: 0;
  font: 22px Arial, sans-serif;
}

h1, h2, h3 {
  font-size: 22px;
  font-weight: normal;
}

a {
  color: red;
  text-decoration: none;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

nav {
  height: 50px;
  box-sizing: border-box;
  width: 100%;
  z-index: 100;
  text-align: center;
  background-color: red;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}

nav .nav-link {
  margin: 0;
  padding: 0;
}

nav .nav-link a {
  text-transform: uppercase;
  color: #fff;
  font-family: Apfel Grotezk Brut, Arial, sans-serif;
  font-size: 28px;
  text-decoration: none;
}

nav .active {
  -webkit-text-decoration: underline #fff;
  text-decoration: underline #fff;
}

.content {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  z-index: 100;
  background-color: #fff;
  padding-bottom: 65px;
  position: absolute;
}

.content p {
  max-width: 650px;
}

.content .header {
  z-index: 100;
  color: #fff;
  height: 50px;
  background-color: red;
  align-items: center;
  padding: 0 15px;
  font-weight: bold;
  display: flex;
  position: sticky;
  top: 0;
}

.spaces.content {
  padding-bottom: 50px;
}

.spaces.content .header {
  text-transform: uppercase;
  font-family: Apfel Grotezk Brut, Arial, sans-serif;
  font-size: 32px;
  font-weight: normal;
}

.spaces.content a {
  color: #000;
  text-decoration: none;
}

.spaces.content a:hover .name .circle-icon {
  background-color: #000;
}

.spaces.content a:hover .name h2 {
  color: #000;
}

.spaces.content .space {
  border-bottom: 2px solid red;
}

.spaces.content .space .name {
  align-items: center;
  padding: 0 15px;
  display: inline-flex;
}

.spaces.content .space .name h2 {
  color: red;
  font-weight: bold;
}

.spaces.content .space .name .circle-icon {
  min-width: 25px;
  margin-right: 1rem;
}

.spaces.content .space .event, .spaces.content .space .exhibition {
  padding: 0 15px 1em;
}

.spaces.content .space .meta {
  color: #999;
  font-size: 16px;
}

.spaces.content .space .space-details {
  color: #000;
  padding: 1em 15px 0;
}

.space.content {
  padding-bottom: 50px;
}

.space.content .spaces-nav {
  width: 20%;
  justify-content: space-between;
  display: flex;
}

.space.content .header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.space.content .header a {
  color: #fff;
}

.space.content .space {
  padding-bottom: 1em;
  padding-left: 15px;
  padding-right: 15px;
}

.space.content .space .space-description {
  color: #999;
  margin-top: 2em;
  font-size: 16px;
}

.space.content .space a {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.space.content .space .name {
  align-items: center;
  display: inline-flex;
}

.space.content .space .name .circle-icon {
  min-width: 25px;
  margin-right: 1rem;
}

.space.content .space .name h2 {
  color: red;
  font-weight: bold;
}

.space.content .space .address, .space.content .space .website {
  font-size: 16px;
}

.space.content .space .address a, .space.content .space .website a {
  color: #000;
  text-decoration: none;
}

.space.content .space .directions {
  color: red;
  justify-content: space-between;
  align-items: center;
  margin-top: .5em;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.space.content .space .directions a {
  -webkit-text-decoration: underline red;
  text-decoration: underline red;
}

.space.content .space .event, .space.content .space .exhibition {
  margin-top: 1em;
}

.space.content .space .event .meta, .space.content .space .exhibition .meta {
  color: #999;
  font-size: 16px;
}

.space.content .space .event .date, .space.content .space .exhibition .date {
  color: #999;
  padding-bottom: .25em;
  font-size: 16px;
}

.space.content .space .event .calendar, .space.content .space .exhibition .calendar {
  color: red;
  padding-top: .5em;
  padding-bottom: .25em;
}

.space.content .space .event .tag, .space.content .space .exhibition .tag {
  color: #999;
  padding-bottom: 0;
  font-size: 16px;
}

.space.content .space .event .title, .space.content .space .exhibition .title {
  max-width: 650px;
  padding-top: 0;
  font-size: 1em;
}

.space.content .space .event .artists, .space.content .space .exhibition .artists, .space.content .space .event .description, .space.content .space .exhibition .description {
  max-width: 650px;
  font-size: 16px;
}

.space.content .space .event .link, .space.content .space .exhibition .link {
  padding-top: .5em;
  font-size: 16px;
  font-weight: bold;
  -webkit-text-decoration: underline red;
  text-decoration: underline red;
}

.space.content .space .event .link a, .space.content .space .exhibition .link a {
  text-decoration-color: red;
}

.space.content .space .space-link {
  padding-top: 1em;
  font-size: .75em;
}

.space.content .space .space-link a {
  text-decoration-color: red;
}

.events.content {
  padding-bottom: 2em;
}

.events.content .calendar-date {
  height: 50px;
  box-sizing: border-box;
  z-index: 100;
  text-transform: uppercase;
  background-color: red;
  align-items: center;
  padding-left: 15px;
  font-family: Apfel Grotezk Brut, Arial, sans-serif;
  font-size: 32px;
  display: flex;
  position: sticky;
  top: 0;
}

.events.content .calendar-date div {
  color: #fff;
  margin: 0;
}

.events.content .event {
  border-bottom: 2px solid red;
  padding: .75em 15px;
}

.events.content .event .calendar-buttons {
  color: red;
  margin-bottom: .75em;
  text-decoration: underline;
}

.events.content .event .link a {
  font-size: 16px;
  font-weight: bold;
}

.events.content .event .description {
  max-width: 650px;
  font-size: 16px;
}

.events.content .event .description .excerpt-button {
  color: red;
  line-height: 1;
  display: inline;
}

.events.content .event .description .full {
  display: none;
}

.events.content .event .description .excerpt {
  max-width: 650px;
  padding-top: 1em;
}

.events.content .event .description.show .excerpt {
  max-width: 650px;
  display: none;
}

.events.content .event .description.show .full {
  display: inline;
}

.events.content .space {
  margin-top: 1em;
  padding-bottom: 0;
  font-size: 16px;
}

.events.content .space .name {
  align-items: center;
  font-weight: bold;
  display: inline-flex;
}

.events.content .space .name a {
  text-decoration: none;
}

.events.content .space .name .circle-icon {
  height: 15px;
  width: 15px;
  min-width: 15px;
  margin-right: .5rem;
}

.events.content .space .name:hover {
  color: #000;
}

.events.content .space .name:hover .circle-icon {
  background-color: #000;
}

.info {
  color: #fff;
  background-color: red;
  border: 2px solid red;
  font-size: 22px;
}

.info .wrapper {
  padding-left: 15px;
  padding-right: 15px;
}

.info .description {
  padding-bottom: 1em;
}

.info .banner {
  text-transform: uppercase;
  padding-top: 2em;
  padding-bottom: 3em;
  font-family: Apfel Grotezk Brut, Arial, sans-serif;
  font-size: 50px;
}

.info a {
  color: #fff;
  text-decoration: underline;
}

.info h1 {
  font-size: 28px;
  font-weight: bold;
}

.info h2 {
  font-size: 22px;
}

.info h3 {
  font-size: 16px;
  font-weight: normal;
}

.info .more-info {
  font-size: 16px;
}

.info .supporters {
  padding-top: 4em;
}

.info .supporters .logos {
  width: 100%;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  gap: 2em;
  display: flex;
}

.info .supporters .logos .logo {
  height: 2em;
}

.info .supporters .logos .logo img {
  height: 100%;
}

.info .footer {
  padding-top: 4em;
  font-size: 14px;
}

add-to-calendar-button {
  margin: 0;
  padding: 0;
}

button, a.button {
  color: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0 .5rem 0 0;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  display: block;
}

input {
  color: #fff;
  font: inherit;
  background: red;
  border: none;
  padding: 0 .5em;
  font-size: .75em;
}

input[type="submit"] {
  width: auto;
  cursor: pointer;
}

.input-sizer {
  vertical-align: top;
  align-items: center;
  display: inline-grid;
  position: relative;
  color: #fff !important;
}

.input-sizer input {
  min-width: 200px;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  padding: .2em .5em;
}

.input-sizer:after, input, textarea {
  color: #fff;
  width: auto;
  min-width: 1em;
  resize: none;
  appearance: none;
  background: none;
  grid-area: 1 / 2;
  padding: 1em 0;
  font-size: 16px;
}

.input-sizer:after {
  content: attr(data-value) " ";
  visibility: hidden;
  white-space: pre-wrap;
}

#mce-success-response, #mce-error-response, #mc_embed_signup div.mce_inline_error {
  background-color: inherit;
  font-size: 16px;
  display: inline;
  font-weight: normal !important;
}

#mc-embedded-subscribe.button {
  color: #fff;
  background-color: red;
  margin-top: .5em;
  padding: .25em 0;
  font-size: 16px;
  font-weight: normal;
  text-decoration: underline;
}

#mc_embed_signup div.mce_inline_error {
  margin-bottom: 0;
  font-size: 16px;
  color: #fff !important;
  background: inherit !important;
  font-weight: normal !important;
}

#map {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 50px);
  z-index: -10;
  background: #fff;
  border-bottom: none;
  position: fixed;
  top: 0;
  left: 0;
}

.active-area {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
}

.leaflet-container {
  font-family: Arial, sans-serif;
}

.circle-icon {
  width: 25px;
  height: 25px;
  background-color: red;
  border-radius: 100px;
}

.circle-icon span {
  width: 100px;
  font-size: 16.5px;
  line-height: 1em;
  display: none;
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}

.circle-icon .mobile {
  font-family: monospace;
  font-size: 22px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle-icon .highlight {
  width: 25px;
  height: 25px;
  background-color: #000;
  border-radius: 100px;
  display: none;
}

.active .mobile, .active span {
  display: block;
}

.leaflet-fade-anim {
  transition: none;
}

.leaflet-control-attribution {
  color: #000;
  font-size: .6em;
}

.leaflet-control-attribution a {
  color: #000;
}

div .leaflet-popup-content-wrapper {
  box-shadow: none;
  text-align: center;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 0;
  font: 16px Arial, sans-serif;
}

div .leaflet-popup-content-wrapper a {
  color: #000;
  text-decoration: none;
}

div .leaflet-popup-close-button {
  display: none;
}

div .leaflet-popup-tip {
  box-shadow: none;
  background-color: #fff;
  font: 16px Arial, sans-serif;
  display: none;
}

.transition-slide {
  transition: all .175s;
  transform: translateX(0);
}

html.is-animating .transition-slide, html.is-animating.from-route-info .transition-slide, html.is-animating.to-route-info .transition-slide {
  transform: translateX(-100vw);
}

html.is-animating, html.is-animating * {
  cursor: progress !important;
}

@media (min-width: 800px) {
  .mobile {
    display: none;
  }

  .tablet {
    display: inline;
  }

  .flex {
    width: 100%;
    flex-wrap: wrap;
    gap: 60px;
    display: flex;
  }

  .flex .two {
    width: 100%;
  }

  .flex .two p {
    max-width: 800px;
  }

  .flex .four {
    width: 45%;
  }

  .content {
    width: 50%;
    border-right: 2px solid red;
  }

  .content .header {
    padding-left: 30px;
    padding-right: 30px;
  }

  .info.content {
    padding-bottom: 30px;
  }

  .info.content .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  .info.content .header-logo {
    padding: 0 20px;
  }

  .space.content {
    padding-bottom: 0;
  }

  .space.content .space {
    padding-left: 30px;
    padding-right: 30px;
  }

  .spaces.content {
    padding-bottom: 0;
  }

  .spaces.content .space .name, .spaces.content .space .event, .spaces.content .space .exhibition, .spaces.content .space .space-details {
    padding-left: 30px;
    padding-right: 30px;
  }

  .events.content {
    padding-bottom: 0;
  }

  .events.content .calendar-date {
    text-align: left;
    padding-left: 30px;
    top: 0;
  }

  .events.content .event {
    flex-wrap: nowrap;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
  }

  .events.content .event .description .cover {
    width: calc(75% - 30px);
  }

  .events.content .event .calendar-buttons {
    margin-top: .75em;
    margin-bottom: 0;
  }

  .events.content .date {
    width: 25%;
  }

  .events.content .event-flex {
    width: 75%;
  }

  add-to-calendar-button {
    text-align: left;
    align-items: center;
    display: flex;
  }

  nav {
    width: 50%;
    left: unset;
    padding: 0 15px;
    top: 0;
    right: 0;
  }

  nav .nav-link {
    margin: 0;
  }

  nav .nav-link a {
    font-size: 32px;
  }

  .content.info {
    width: 100%;
    border: none;
    padding-top: 50px;
  }

  .content.info .banner {
    font-size: 50px;
  }

  #map {
    height: 100%;
    top: 0;
  }

  .active-area {
    width: 50%;
    left: 50%;
  }

  .leaflet-control-attribution {
    font-size: .8em;
  }

  .circle-icon span {
    width: 200px;
  }

  .circle-icon .highlight, .active .mobile {
    display: none;
  }

  .clicked .highlight {
    display: inline-block;
  }

  html.is-animating .transition-slide, html.is-animating.to-route-info .transition-slide {
    transform: translateX(-50%);
  }
}

@media (min-width: 1280px) {
  .mobile {
    display: none;
  }

  .desktop {
    display: inline;
  }

  .content {
    width: 40%;
    max-width: 800px;
  }

  #map .active-area {
    width: 60%;
    left: 40%;
  }

  nav {
    width: 50%;
    max-width: 800px;
  }

  nav .nav-link a {
    font-size: 50px;
  }

  .info.content {
    max-width: 100%;
  }

  .info.content .header-logo {
    width: 85%;
    padding: 0;
  }

  .info.content .tablet {
    display: none;
  }

  .spaces.content .header, .events.content .calendar-date {
    font-size: 50px;
  }

  .flex {
    width: 100%;
    flex-wrap: nowrap;
    gap: 60px;
    display: flex;
  }

  .flex .two {
    width: 100%;
  }

  .flex .four {
    width: 25%;
  }
}

@media (min-width: 2000px) {
  #map .active-area {
    width: calc(100% - 800px);
    left: 800px;
  }
}

/*# sourceMappingURL=main.css.map */
